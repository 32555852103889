import $axios from "../../api";
import FileSaver from "file-saver";

const state = () => ({
  data: [],
  isLoading: false,
  isErrors: "",
  isSuccess: false,
  list: null,
  listLog: null,
  detail: null,
  page: 1,
  // pagination: {
  //   current_page: 1,
  //   total: 0,
  //   per_page: 20,
  //   last_page: 1,
  // },
  // paginationModal: {
  //   total: 0,
  //   per_page: 10,
  //   current_page: 1,
  //   last_page: 0,
  // },
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  // SET_PAGINATION(state, payload) {
  //   state.pagination = payload;
  // },
  // SET_PAGINATION_MODAL(state, payload) {
  //   state.paginationModal = payload; // Fixed assignment
  // },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_LIST_LOG(state, payload) {
    state.listLog = payload;
  },
};

const actions = {
  async getStockObtsList({ commit, dispatch, state, rootState }, query = "") {
    commit("SET_LOADING", true);
    try {
      const response = await $axios.get(
        `dashboardsync/stock-obts/list?page=${state.page}&view=${rootState.Main.view}${query}`
      );
      commit("SET_LIST", response.data.data);
      // commit("SET_PAGINATION", {
      //   current_page: response.data.data.current_page,
      //   total: response.data.data.total,
      //   per_page: response.data.data.per_page,
      //   last_page: response.data.data.last_page,
      // });
    } catch (error) {
      commit("SET_LOADING", false);
      dispatch("Main/globalSweetAlert", error.response, { root: true });
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },

  getDetail({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`dashboardsync/stock-obts/view${payload}`)
        .then((res) => {
          commit("SET_DETAIL", res.data);
          // commit("SET_PAGINATION_MODAL", res.data.pagination || {});
          commit("SET_LOADING", false);
          resolve(res.data);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch(
            "Main/globalSweetAlert",
            error.response || { message: "Unknown error" },
            { root: true }
          );
          reject(error);
        });
    });
  },
  getLog({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    // var page = state.page;
    // payload.page = page;
    return new Promise((resolve, reject) => {
      $axios
        .post('dashboardsync/stock-obts/log', payload)
        .then((res) => {
          commit('SET_LIST_LOG', res.data);
          commit('SET_LOADING', false);
          resolve(res.data);
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch(
            'Main/globalSweetAlert',
            error.response || { message: 'Unknown error' },
            { root: true }
          );
          reject(error);
        });
    });
  },

  exportDataStock({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `/dashboardsync/stock-obts/export?page=${state.page}&view=${rootState.Main.view}${payload || ""}`,
          { responseType: "blob" }
        )
        .then((res) => {
          commit("SET_LOADING", false);
          FileSaver.saveAs(
            new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
            `Export_Data_Stock.xlsx`
          );
          resolve(res.data);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
