import $axios from "../../api";

const state = () => ({
  data: [],
  modalData: [],
  isLoading: false,
});

const mutations = {
  SET_DATA(state, payload) {
    state.data = payload;
  },
  SET_MODAL_DATA(state, payload) {
    state.modalData = payload;
  },
  SET_RESYNC_STATE(state, { index, item }) {
    state.modalData.splice(index, 1, item);
  },
  SET_IS_RESYNCING(state, payload) {
    state.isResyncing = payload;
  },
  SET_RESYNC_BUTTON_TEXT(state, payload) {
    state.resyncButtonText = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};

const actions = {
  getDashboardExternalData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`dashboardsync/data/list`)
        .then((response) => {
          commit("SET_DATA", response.data.data);
          commit("SET_LOADING", false);
          resolve(response);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
          reject(error);
        });
    });
  },
  async resyncExternalData({ commit, dispatch, state }, { item, index }) {
    // Mengubah status item sebelum resync
    commit('SET_RESYNC_STATE', { index, item: { ...item, isResyncing: true } });

    try {
      const endpoint = `dashboardsync/resync/${item.endpoint_resync}`;
      const response = await $axios.post(endpoint, { sync_master_id: [item.id] });

      // Mengambil ulang data dashboard setelah resync berhasil
      await dispatch('getDashboardExternalData');

      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        icon: "success",
        text: `${item.key_value} Resync successful`,
        showCancelButton: false,
      });

      return response;

    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        icon: "error",
        html: `Error resyncing data: ${error.message}`,
        showCancelButton: false,
      });
      throw error;

    } finally {
      commit('SET_RESYNC_STATE', { index, item: { ...item, isResyncing: false } });

      const tableKey = item.table_name;
      let updatedList;

      if (item.endpoint_resync === 'web') {
        updatedList = state.data.sync_table_toko_to_unionweb[tableKey]?.list || state.modalData;
      } else {
        updatedList = state.data.sync_table_unionweb_to_union[tableKey]?.list || state.modalData;
      }

      // Mengupdate modal data dengan list yang baru
      commit('SET_MODAL_DATA', updatedList);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
