import $axios from "../../api";

const state = () => ({
  isLoading: true,
  selectLoad: false,
  isErrors: "",
  access: "",
  accessChild: "",
  view: 20,
  roleList: [],
  groupList: [],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  ASSIGN_ACCESS(state, payload) {
    state.access = payload;
    setTimeout(() => {
      state.isLoading = false;
    }, 200);
  },
  ASSIGN_ACCESS_CHILD(state, payload) {
    state.accessChild = payload;
  },
  SET_VIEW(state, payload) {
    state.view = payload;
  },
  SET_ROLE_LIST(state, payload) {
    state.roleList = payload;
  },
  SET_GROUP_LIST(state, payload) {
    state.groupList = payload;
  },
  SET_SELECT_LOAD(state, payload) {
    state.selectLoad = payload;
  },
};

const actions = {
  getCheckModuleAccess({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`module/checkmoduleaccess`, payload)
        .then((res) => {
          commit("ASSIGN_ACCESS", res.data.data.access);
          commit("ASSIGN_ACCESS_CHILD", res.data.data.access_child);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getGroupList({ commit, state, dispatch }, payload) {
    commit("SET_SELECT_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/group?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_GROUP_LIST", res.data.data);
          commit("SET_SELECT_LOAD", false);
        })
        .catch((error) => {
          commit("SET_SELECT_LOAD", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getRoleList({ commit, state, dispatch }, payload) {
    commit("SET_SELECT_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`select/role?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("SET_ROLE_LIST", res.data.data);
          commit("SET_SELECT_LOAD", false);
        })
        .catch((error) => {
          commit("SET_SELECT_LOAD", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  changeView({ commit, state }, payload) {
    commit("SET_VIEW", payload);
  },
  globalSuccessSweetAlert({ commit, state }, payload) {
    Swal.fire({
      icon: "success",
      html: `<p class="font-size-110rem mb-2">${payload}</p>`,
    });
  },
  globalSweetAlert({ commit, state }, payload) {
    if (payload.status == 403) {
      Swal.fire({
        icon: "warning",
        html: `<p class="font-size-100rem mb-1 font-weight-bold">${payload.data.message}</p>
                <p class="font-size-100rem mb-0 font-weight-normal">Please contact your administrator!</p>`,
      });
    } else if (payload.status == 401) {
      Swal.fire({
        icon: "warning",
        html: `<p class="font-size-100rem mb-0 font-weight-normal">Your session expired, please login again!</p>`,
      });
      window.location.reload();
    } else if (payload.status >= 400 && payload.status < 500) {
      Swal.fire({
        icon: "warning",
        html: `<p class="font-size-100rem mb-1 font-weight-bold">${payload.data.message}</p>
                <p class="font-size-100rem mb-0 font-weight-normal">Please check your data!</p>`,
      });
    } else if (payload.status == 500) {
      Swal.fire({
        icon: "error",
        html: `<h3>Something Went Wrong</h3> <h4 class="font-size-085rem font-weight-bold">error code: 500</h4> <p class="font-size-085rem mb-3">${payload.data.message}</p>`,
        // <p class="font-size-100rem mb-0 font-weight-bold text-danger">On Line: ${payload.data.line}</p>,
        // footer: '<a href="wa.me/6281219893252">Report this issue</a>',
      });
    } else {
      Swal.fire({
        icon: "error",
        html: `<h3>Something Went Wrong</h3> <h4 class="font-size-085rem font-weight-bold">error code: ${payload.status}</h4> <p class="font-size-085rem mb-3">${payload}</p>`,
        // <p class="font-size-100rem mb-0 font-weight-bold text-danger">On Line: ${payload.data.line}</p>,
        // footer: '<a href="wa.me/6281219893252">Report this issue</a>',
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
