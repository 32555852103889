import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  sideBarMenu: [],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SIDEBAR_DATA(state, payload) {
    state.sideBarMenu = payload;
  },
};

const actions = {
  getSideBarMenu({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`module`)
        .then((res) => {
          commit("SET_SIDEBAR_DATA", res.data.sidebar);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
