import $axios from "../../../api";

const state = () => ({
  isLoading: false,
  isErrors: {},
  isSuccess: false,
  page: 1,
  promoList: [],
  promoData: {},
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  ASSIGN_PROMO_LIST(state, payload) {
    state.promoList = payload;
  },
  ASSIGN_PROMO_DATA(state, payload) {
    state.promoData = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `promorpercentd/list?page=${state.page}&view=${rootState.Main.view
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("ASSIGN_PROMO_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  viewPromo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`promorpercentd/view?id=${payload}`)
        .then((res) => {
          commit("ASSIGN_PROMO_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  createPromo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_SUCCESS", false)
    commit("ASSIGN_ERRORS", "");
    return new Promise((resolve, reject) => {
      $axios
        .post(`promorpercentd/create`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  updatePromo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_SUCCESS", false)
    commit("ASSIGN_ERRORS", "");
    return new Promise((resolve, reject) => {
      $axios
        .post(`promorpercentd/update`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  showHidePromo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`promorpercentd/showhide`, payload)
        .then((res) => {
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
