import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  moduleList: [],
  moduleData: {},
  createLoading: false,
  isSuccess: false,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  ASSIGN_MODULE_LIST(state, payload) {
    state.moduleList = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_MODULE_DATA(state, payload) {
    state.moduleData = payload;
  },
  SET_CREATE_LOAD(state, payload) {
    state.createLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.isErrors = payload;
  },
};

const actions = {
  getModuleList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `module/list?page=${state.page}&view=${rootState.Main.view
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("ASSIGN_MODULE_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  showhide({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .put(`module/showhide`, payload)
        .then((response) => {
          commit("SET_SUCCESS", true);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getModuleData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`module/view?module_id=${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_MODULE_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createModuleData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`module/create`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateModuleData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`module/update`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
