export default [
  {
    name: "cpage_homepage",
    path: "/career/homepage",
    component: ()=> import("../pages/career/homepage/index")
  },
  {
    name: "cpage_create",
    path: "/career/homepage/create",
    component: ()=> import("../pages/career/homepage/manage")
  },
  {
    name: "cpage_contactus",
    path: "/career/contactus",
    component: ()=> import("../pages/career/contactus/index")
  },
  {
    name: "cpage_career",
    path: "/career/career",
    component: ()=> import("../pages/career/career/index")
  },
  {
    name: "cpage_update",
    path: "/career/homepage/update/:id",
    component: ()=> import("../pages/career/homepage/manage")
  },

  // ==============================================================

  {
    name: "cbanner_list",
    path: "/career/banner",
    component: ()=> import("../pages/career/banner/index")
  },
  {
    name: "cbanner_create",
    path: "/career/banner/create",
    component: ()=> import("../pages/career/banner/create")
  },
  {
    name: "cbanner_update",
    path: "/career/banner/update/:id",
    component: ()=> import("../pages/career/banner/update")
  },
  {
    name: "cjob_list",
    path: "/career/jobs",
    component: ()=> import("../pages/career/jobs/index")
  },
  {
    name: "cjob_create",
    path: "/career/jobs/create",
    component: ()=> import("../pages/career/jobs/create")
  },
  {
    name: "cjob_update",
    path: "/career/jobs/update/:id",
    component: ()=> import("../pages/career/jobs/update")
  },
  {
    name: "capplyjob_list",
    path: "/career/applyjob",
    component: ()=> import("../pages/career/applyjob/index")
  },
  {
    name: "capplyjob_view",
    path: "/career/applyjob/view/:id",
    component: ()=> import("../pages/career/applyjob/view")
  },
];
