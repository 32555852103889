import $axios from "../../api";

// MODULE NAME
const module = "website/shipping";

const state = () => ({
    isLoading: false,
    isErrors: "",
    page: 1,
    isSuccess: false,
    list: null,
    detail: null,
});

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
    ASSIGN_ERRORS(state, payload) {
        state.isErrors = payload;
    },
    SET_PAGE(state, payload) {
        state.page = payload;
    },
    SET_IS_SUCCESS(state, payload) {
        state.isSuccess = payload;
    },
    SET_LIST(state, payload) {
        state.list = payload;
    },
    SET_DETAIL(state, payload) {
        state.detail = payload;
    },
};

const actions = {
    pickupAnteraja({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("WebOrder/SET_LOADING", true, { root: true });
        commit("SET_IS_SUCCESS", false);
        commit("WebOrder/SET_IS_SUCCESS", false, { root: true });
        return new Promise((resolve, reject) => {
            $axios
                .post(`${module}/antr/pickup`, payload)
                .then((res) => {
                    commit("SET_IS_SUCCESS", true);
                    commit("WebOrder/SET_IS_SUCCESS", true, { root: true });
                    commit("WebOrder/SET_LOADING", false, { root: true });
                    commit("SET_LOADING", false);
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    commit("WebOrder/SET_LOADING", false, { root: true });
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    pickupGosend({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("WebOrder/SET_IS_SUCCESS", false, { root: true });
        commit("WebOrder/SET_LOADING", true, { root: true });
        commit("SET_IS_SUCCESS", false);
        return new Promise((resolve, reject) => {
            $axios
                .post(`${module}/gosend/pickup`, payload)
                .then((res) => {
                    commit("WebOrder/SET_IS_SUCCESS", true, { root: true });
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                    commit("WebOrder/SET_LOADING", false, { root: true });
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    commit("WebOrder/SET_LOADING", false, { root: true });
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
    pickupLalamove({ commit, state, rootState, dispatch }, payload) {
        commit("SET_LOADING", true);
        commit("SET_IS_SUCCESS", false);
        commit("WebOrder/SET_IS_SUCCESS", false, { root: true });
        commit("WebOrder/SET_LOADING", true, { root: true });
        return new Promise((resolve, reject) => {
            $axios
                .post(`${module}/lalamove/pickup`, payload)
                .then((res) => {
                    commit("WebOrder/SET_IS_SUCCESS", true, { root: true });
                    commit("SET_IS_SUCCESS", true);
                    commit("SET_LOADING", false);
                    commit("WebOrder/SET_LOADING", false, { root: true });
                })
                .catch((error) => {
                    commit("ASSIGN_ERRORS", error.response.data.errors);
                    commit("SET_LOADING", false);
                    commit("WebOrder/SET_LOADING", false, { root: true });
                    dispatch("Main/globalSweetAlert", error.response, {
                        root: true,
                    });
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
