/*
    Here place for import global component that can also be called on the blade
*/

import Vue from "vue";


const vueSideBar = () => import("./components/global/sidebar.vue");
const checkAccess = () => import("./components/global/loadAccess.vue");
const headerTitle = () => import("./components/global/headerTitle.vue");
const assignAccess = () => import("./components/global/assignAccess.vue");

Vue.component("vue-sidebar", vueSideBar);
Vue.component("check-access", checkAccess);
Vue.component("header-title", headerTitle);
Vue.component("assign-access", assignAccess);
