export default [
  // ================ Stock ================
  {
    name: "stockobts_index",
    path: "/inv/stock-obts",
    component: () => import("../pages/union/stock/index.vue"),
  },
  {
    name: "stockobts_log",
    path: "/inv/stock-obts/log",
    component: () => import("../pages/union/stock/logStock.vue"),
  },
];


