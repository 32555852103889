import $axios from "../../api";

const state = {
  userProfile: "",
  isLoading: false,
  errors: "",
  isSuccess: false,
};

const mutations = {
  SET_PROFILE(state, payload) {
    state.userProfile = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
};

const actions = {
  updatePassword({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`profile/updatepassword`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
