import reseller from "./reseller";
import brand from "./brand";
import career from "./career";
import website from "./website";
import inventory from "./inv";
import union from "./union";
import hr from "./hr";

export default [
  /** GLOBAL ROUTES */
  { path: "*", component: () => import("../pages/global/error/404.vue") },
  {
    path: "/403",
    component: () => import("../pages/global/error/403.vue"),
  },
  // ================ Import External Route ================
  ...reseller,
  ...brand,
  ...career,
  ...website,
  ...inventory,
  ...union,
  ...hr,
  // ================ dashboard ================
  {
    name: "dashboard_index",
    path: "/dashboard",
    component: () => import("../pages/homepage/Dashboard"),
  },
  {
    name: "dashboard_indexexternal",
    path: "/dashboard-external",
    component: () => import("../pages/homepage/DashboardExternal"),
  },
  {
    name: "dashboard_indexcareer",
    path: "/dashboard-career",
    component: () => import("../pages/homepage/DashboardCareer"),
  },
  {
    name: "dashboard_indexwebsite",
    path: "/dashboard-website",
    component: () => import("../pages/homepage/DashboardWebsite.vue"),
  },
  {
    name: "dashboard_indexit",
    path: "/dashboard-it",
    component: () => import("../pages/homepage/DashboardIT.vue"),
  },
  {
    name: "profile_index",
    path: "/profile",
    component: () => import("../pages/global/profile/Profile.vue"),
  },

  // ================ user ================
  {
    name: "user_index",
    path: "/users",
    component: () => import("../pages/global/user/index.vue"),
  },
  {
    name: "user_create",
    path: "/users/create",
    component: () => import("../pages/global/user/manage.vue"),
  },
  {
    name: "user_update",
    path: "/users/update/:id",
    component: () => import("../pages/global/user/manage.vue"),
  },
  {
    name: "user_assign",
    path: "/users/assign-access/:id",
    component: () => import("../pages/global/user/assign.vue"),
  },
  {
    name: "user_log",
    path: "/users/log-activity",
    component: () => import("../pages/global/user/log.vue"),
  },

  // ================ module ================
  {
    name: "module_index",
    path: "/modules",
    component: () => import("../pages/global/modules/index.vue"),
  },
  {
    name: "module_create",
    path: "/modules/create",
    component: () => import("../pages/global/modules/manage.vue"),
  },
  {
    name: "module_update",
    path: "/module/update/:id",
    component: () => import("../pages/global/modules/manage.vue"),
  },

  //================ role ================
  {
    name: "role_index",
    path: "/role",
    component: () => import("../pages/global/role/index.vue"),
  },
  {
    name: "role_create",
    path: "/role/create",
    component: () => import("../pages/global/role/manage.vue"),
  },
  {
    name: "role_update",
    path: "/role/update/:id",
    component: () => import("../pages/global/role/manage.vue"),
  },

  //================ product ================
  {
    name: "product_index",
    path: "/product",
    component: () => import("../pages/global/product/index.vue"),
  },
  {
    name: "productr_create",
    path: "/product/create",
    component: () => import("../pages/global/product/create.vue"),
  },
  {
    name: "productr_duplicatevariant",
    path: "/product/duplicate-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productrvariant_update",
    path: "/product/update-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productrvariant_create",
    path: "/product/create-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productr_update",
    path: "/product/update/:id",
    component: () => import("../pages/global/product/updateProduct.vue"),
  },
  // {
  //   name: "product_logstock",
  //   path: "/product/log-stock",
  //     component: ()=> import("../pages/global/product/logStock.vue")
  // },

  // ================ member ================
  {
    name: "member_index",
    path: "/member",
    component: () => import("../pages/global/member/index.vue"),
  },
  {
    name: "member_create",
    path: "/member/create",
    component: () => import("../pages/global/member/create.vue"),
  },
  {
    name: "member_update",
    path: "/member/update/:uuid",
    component: () => import("../pages/global/member/update.vue"),
  },
  // ================ sync union ================
  {
    name: "synctoko_index",
    path: "/synctoko",
    component: () => import("../pages/global/syncunion/index.vue"),
  },
  {
    name: "orderstore_index",
    path: "/stores/orders",
    component: () => import("../pages/global/syncunion/statistics.vue"),
  },
  // ================ price group ================
  {
    name: "pricegroup_index",
    path: "/pricegroup",
    component: () => import("../pages/global/priceGroup/index.vue"),
  },
  // ================ user price group ================
  {
    name: "priceuser_index",
    path: "/priceuser",
    component: () => import("../pages/global/userPriceGroup"),
  },
  {
    name: "priceuser_create",
    path: "/priceuser/create",
    component: () => import("../pages/global/userPriceGroup/manage.vue"),
  },
  {
    name: "priceuser_update",
    path: "/priceuser/update/:id",
    component: () => import("../pages/global/userPriceGroup/manage.vue"),
  },
];
