const state = () => ({
  selectedProduct: [],
  startDate: "",
  endDate: "",
  startTime: "00:00:00",
  endTime: "23:59:00",
  start_date_time: "",
  end_date_time: "",
});

const mutations = {
  SET_SELECTED_PRODUCT(state, payload) {
    state.selectedProduct = payload;
  },
  SET_START_DATE(state, payload) {
    state.startDate = payload;
  },
  SET_END_DATE(state, payload) {
    state.endDate = payload;
  },
  SET_START_TIME(state, payload) {
    state.startTime = payload;
  },
  SET_END_TIME(state, payload) {
    state.endTime = payload;
  },
  SET_START_DATE_TIME(state, payload) {
    state.start_date_time = payload;
  },
  SET_END_DATE_TIME(state, payload) {
    state.end_date_time = payload;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
