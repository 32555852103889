import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  selectedCategory: [{
    "category_id": null,
    "sub_category_id": null,
    "unit_id": null
  }],
  productList: [],
  productDetail: "",
  isSuccess: false,
  priceProductHistoryList: [],
  productPrice:"",
  variantData: "",
  changePriceSuccess: false,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  ASSIGN_PRODUCT_LIST(state, payload) {
    state.productList = payload;
  },
  SET_SELECTED_CATEGORY(state, payload) {
    state.selectedCategory = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_PRICE_IS_SUCCESS(state, payload) {
    state.changePriceSuccess = payload;
  },
  ASSIGN_PRODUCT_DETAIL(state, payload) {
    state.productDetail = payload;
  },
  ASSIGN_PRICE_PRODUCT_HISTORY_LIST(state, payload) {
    state.priceProductHistoryList = payload;
  },
  ASSIGN_PRODUCT_PRICE(state, payload) {
    state.productPrice = payload;
  },
  ASSIGN_VARIANT_DATA(state, payload) {
    state.variantData = payload;
  },
};

const actions = {
  getAllProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/list?view=${rootState.Main.view}&page=${state.page}${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/listvariant${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createProductVariant({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`product/reseller/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createVariant({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`product/reseller/createvariant`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  hideShowVariant({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`product/reseller/showhidevariant`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getProductData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/view?uuid=${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_DETAIL", res.data.data)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`product/reseller/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  priceProductHistory({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/listprice${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_PRICE_PRODUCT_HISTORY_LIST", res.data.data)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getPriceProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/viewprice${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_PRODUCT_PRICE", res.data.data)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  changePriceProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_PRICE_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`product/reseller/createupdateprice`, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", true)
          commit("SET_PRICE_IS_SUCCESS", true)
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_PRICE_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  moveProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`product/reseller/movevariant`, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", true)
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  addSkuSap({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`product/insertskusap`, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", true)
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getVariantData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`product/reseller/viewvariant?uuid=${payload ? payload : ''}`)
        .then((res) => {
          commit("ASSIGN_VARIANT_DATA", res.data.data)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  variantUpdate({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`product/reseller/updatevariant`, payload)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", true)
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
