import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  modulesList: [],
  selectedModule: [],
  roleData: "",
  roleList: [],
  isSuccess: false,
  page: 1,
  createLoading: false,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  ASSIGN_MODULES(state, payload) {
    state.modulesList = payload;
  },
  ASSIGN_ROLES(state, payload) {
    state.roleList = payload;
  },
  ASSIGN_SELECTED_MODULE(state, payload) {
    state.selectedModule = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_CREATE_LOAD(state, payload) {
    state.createLoading = payload;
  },
  SET_ROLE_DATA(state, payload) {
    state.roleData = payload;
  },
};

const actions = {
  getListAll({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`module/list-all`)
        .then((res) => {
          if(payload.is_attach_all){
            commit("ASSIGN_SELECTED_MODULE", res.data.all_module_id);
          }
          
          commit("ASSIGN_MODULES", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getListRole({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`role/list?page=${state.page}&view=${rootState.Main.view}${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_ROLES", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  showhide({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .put(`role/showhide`, payload)
        .then((response) => {
          commit("SET_SUCCESS", true);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getRoleData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`role/view?role_id=${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_ROLE_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  createRoleData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`role/create`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  updateRoleData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`role/update`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
