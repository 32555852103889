import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isSuccess: false,
  isErrors: "",
  page: 1,

  dashboardData: {}
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },

  ASSIGN_DATA(state, payload) {
    state.dashboardData = payload;
  },
}

const actions = {
  getDashboardData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios.get(`data/dashboard-stats`)
        .then((res) => {
          commit("ASSIGN_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};