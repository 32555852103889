import $axios from "../../api";

const PREFIX = "dashboardsync/offline-store/chart"

const state = () => ({
  statisticsData: [],
  isLoading: false,
  isLoadingMonthly: false,
  isLoadingTop: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  selectedStore: "",
  selectedMonthlySales: "",
  selectedTopSales: "",
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_MONTHLY(state, payload) {
    state.isLoadingMonthly = payload;
  },
  SET_LOADING_TOP(state, payload) {
    state.isLoadingTop = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_DATA(state, payload) {
    state.statisticsData = payload;
  },
  SET_SELECTED_STORE(state, selectedStore) {
    state.selectedStore = selectedStore;
  },
  SET_SELECTED_MONTHLY_SALES(state, selectedMonthlySales) {
    state.selectedMonthlySales = selectedMonthlySales;
  },
  SET_SELECTED_TOP_SALES(state, selectedTopSales) {
    state.selectedTopSales = selectedTopSales;
  }
};

const actions = {
  async fetchStatisticsData({ commit, dispatch, state }, selectedStore) {
    commit("SET_LOADING", true);
    commit("SET_LOADING_MONTHLY", true);
    commit("SET_LOADING_TOP", true);
    try {
      var filterMonth = state.selectedMonthlySales;
      var filterTop = state.selectedTopSales;
      let url = `${PREFIX}`;
      const params = [];
      if (filterMonth !== undefined && filterMonth !== null && filterMonth !== '') {
        params.push(`date_monthly_sales=${filterMonth}`);
      }
      if (filterTop !== undefined && filterTop !== null && filterTop !== '') {
        params.push(`date_top_sales=${filterTop}`);
      }
      if (selectedStore) {
        params.push(`store_code=${selectedStore}`);
      }
      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }
      const res = await $axios.get(url);

      commit("SET_DATA", res.data.data);
      commit("SET_IS_SUCCESS", true);
      commit("SET_SELECTED_STORE", selectedStore);
      commit("SET_SELECTED_MONTHLY_SALES", filterMonth);
      commit("SET_SELECTED_TOP_SALES", filterTop);
    } catch (error) {
      commit("ASSIGN_ERRORS", error.response);
      dispatch("Main/globalSweetAlert", error.response, { root: true });
    } finally {
      commit("SET_LOADING", false);
      commit("SET_LOADING_MONTHLY", false);
      commit("SET_LOADING_TOP", false);
    }
  },

  async filterMonthlySales({ commit, dispatch }, { selectedMonthlySales, selectedTopSales, selectedStore }) {
    commit("SET_LOADING_MONTHLY", true);
    try {
      let url = `${PREFIX}`;
      const params = [];
      if (selectedMonthlySales) {
        params.push(`date_monthly_sales=${selectedMonthlySales}`);
      }
      if (selectedTopSales) {
        params.push(`date_top_sales=${selectedTopSales}`);
      }
      if (selectedStore) {
        params.push(`store_code=${selectedStore}`);
      }
      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }
      const res = await $axios.get(url);
      commit("SET_DATA", res.data.data);
      commit("SET_IS_SUCCESS", true);
      commit("SET_SELECTED_MONTHLY_SALES", selectedMonthlySales);
      commit("SET_SELECTED_STORE", selectedStore);
    } catch (error) {
      commit("ASSIGN_ERRORS", error.response);
      dispatch("Main/globalSweetAlert", error.response, { root: true });
    } finally {
      commit("SET_LOADING_MONTHLY", false);
    }
  },

  async filterTopSales({ commit, dispatch }, { selectedMonthlySales, selectedTopSales, selectedStore }) {
    commit("SET_LOADING_TOP", true);
    try {
      let url = `${PREFIX}`;
      const params = [];
      if (selectedMonthlySales) {
        params.push(`date_monthly_sales=${selectedMonthlySales}`);
      }
      if (selectedTopSales) {
        params.push(`date_top_sales=${selectedTopSales}`);
      }
      if (selectedStore) {
        params.push(`store_code=${selectedStore}`);
      }
      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }
      const res = await $axios.get(url);
      commit("SET_DATA", res.data.data);
      commit("SET_IS_SUCCESS", true);
      commit("SET_SELECTED_TOP_SALES", selectedTopSales);
      commit("SET_SELECTED_STORE", selectedStore);
    } catch (error) {
      commit("ASSIGN_ERRORS", error.response);
      dispatch("Main/globalSweetAlert", error.response, { root: true });
    } finally {
      commit("SET_LOADING_TOP", false);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
