import $axios from "../../api";
import FileSaver from "file-saver";

// MODULE NAME
const module = "inv/stock";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
  listLog: null,
  detail: null,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_LIST_LOG(state, payload) {
    state.listLog = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/list?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getListLog({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/log?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST_LOG", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/view${payload}`)
        .then((res) => {
          commit("SET_DETAIL", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  create({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .put(`${module}/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  setStockIn({ commit, state, rootState, dispatch }, payload) {
    commit("ASSIGN_ERRORS", "");
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/in`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  setStockOut({ commit, state, rootState, dispatch }, payload) {
    commit("ASSIGN_ERRORS", "");
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/out`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  update({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .put(`${module}/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  exportDataStock({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/export-data-stock?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`,
          { responseType: "blob" }
        )
        .then((res) => {
          commit("SET_LOADING", false);
          FileSaver.saveAs(
            res.data,
            `Export_Data_Stock_Query?page=${state.page}&view=${rootState.Main.view
            }${payload || ""}.xlsx`
          );
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  exportDataLogStock({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/export-log-stock?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`,
          { responseType: "blob" }
        )
        .then((res) => {
          commit("SET_LOADING", false);
          FileSaver.saveAs(
            res.data,
            `Export_Data_Log_Stock_Query?page=${state.page}&view=${rootState.Main.view
            }${payload || ""}.xlsx`
          );
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  exportStockCard({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/export-stock-card?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`,
          { responseType: "blob" }
        )
        .then((res) => {
          commit("SET_LOADING", false);
          FileSaver.saveAs(
            res.data,
            `Stock_Card_${payload || ""}.pdf`
          );
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
