import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  isSuccess: false,
  page: 1,
  list: [],
  dataView: {},
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_CREATE_LOAD(state, payload) {
    state.createLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_VIEW(state, payload) {
    state.dataView = payload;
  },
};

const actions = {
  list({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    let query_str = payload;
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `career/applyjob/list?page=${state.page}&view=${rootState.Main.view
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  View({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    let id = payload;
    return new Promise((resolve, reject) => {
      $axios
        .get(`career/applyjob/view?id=` + id)
        .then((res) => {
          commit("SET_VIEW", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  update({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`career/applyjob/update`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
