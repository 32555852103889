import $axios from "../../api";

// MODULE NAME
const module = "inv/allocation"

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("ASSIGN_ERRORS", "");
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/list?sku=${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  create({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

