import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  isSuccess: false,
  page: 1,
  view: 20,

  list: [],
  listDetail: {}
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_VIEW(state, payload) {
    state.view = payload;
  },

  ASSIGN_LIST(state, payload) {
    state.list = payload;
  },
  ASSIGN_DATA(state, payload) {
    state.listDetail = payload;
  },
}

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`career/page-content/list?page=${state.page}&view=${state.view}${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getListHomePage({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`career/page-content/listhomepage`)
        .then((res) => {
          commit("ASSIGN_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getSingleData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`career/page-content/view?id=${payload ? payload : ""}`)
        .then((response) => {
          commit("ASSIGN_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createContent({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`career/page-content/create`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          commit("SET_SUCCESS", false);
          // dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateContent({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`career/page-content/update`, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors);
          commit("SET_LOADING", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  showhideContent({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .put(`career/page-content/showhide`, payload)
        .then((response) => {
          commit("SET_SUCCESS", true);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};