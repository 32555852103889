export default [
  // ================ Order ================
  {
    name: "orderreseller_index",
    path: "/order/reseller",
    component: () => import("../pages/reseller/order/index.vue"),
  },
  {
    name: "orderreseller_create",
    path: "/order/reseller/create",
    component: () => import("../pages/reseller/order/manage.vue"),
  },
  // {
  //     name: "orderreseller_update",
  //     path: "/order/reseller/update/:id",
  //     component: ()=> import("../pages/reseller/order/manage.vue")
  // },
  {
    name: "orderreseller_exportdtw",
    path: "/order/reseller/export-dtw",
    component: () => import("../pages/reseller/order/exportDtw.vue"),
  },
  {
    name: "shippingr_index",
    path: "/order/reseller/shipping/:uuid",
    component: () => import("../pages/reseller/order/shipping.vue"),
  },
  // ================ Warehouse ================
  {
    name: "warehouse_index",
    path: "/warehouse",
    component: () => import("../pages/global/warehouse/index.vue"),
  },
  // ================ Promotions ================
  // disc price
  {
    name: "promorprice_index",
    path: "/promotions/disc-price",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/index.vue"),
  },
  {
    name: "promorprice_create",
    path: "/promotions/disc-price/create",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/manage.vue"),
  },
  {
    name: "promorprice_update",
    path: "/promotions/disc-price/update/:id",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/manage.vue"),
  },
  // disc percent
  {
    name: "promorpercent_index",
    path: "/promotions/disc-percent",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/index.vue"),
  },
  {
    name: "promorpercent_create",
    path: "/promotions/disc-percent/create",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/manage.vue"),
  },
  {
    name: "promorpercent_update",
    path: "/promotions/disc-percent/update/:id",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/manage.vue"),
  },
  // disc percent double
  {
    name: "promorpercentd_index",
    path: "/promotions/disc-percent-double",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/index.vue"
      ),
  },
  {
    name: "promorpercentd_create",
    path: "/promotions/disc-percent-double/create",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/manage.vue"
      ),
  },
  {
    name: "promorpercentd_update",
    path: "/promotions/disc-percent-double/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/manage.vue"
      ),
  },
  // buy 1 get 1
  {
    name: "promorbg_index",
    path: "/promotions/buy1-get1",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/index.vue"),
  },
  {
    name: "promorbg_create",
    path: "/promotions/buy1-get1/create",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/manage.vue"),
  },
  {
    name: "promorbg_update",
    path: "/promotions/buy1-get1/update/:id",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/manage.vue"),
  },
  // min buy get free
  {
    name: "promorminbg_index",
    path: "/promotions/min-buy-get-free",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/index.vue"
      ),
  },
  {
    name: "promorminbg_create",
    path: "/promotions/min-buy-get-free/create",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/manage.vue"
      ),
  },
  {
    name: "promorminbg_update",
    path: "/promotions/min-buy-get-free/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/manage.vue"
      ),
  },
  // min buy get free mix
  {
    name: "promorminbgmix_index",
    path: "/promotions/min-buy-get-free-mix",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/index.vue"
      ),
  },
  {
    name: "promorminbgmix_create",
    path: "/promotions/min-buy-get-free-mix/create",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/manage.vue"
      ),
  },
  {
    name: "promorminbgmix_update",
    path: "/promotions/min-buy-get-free-mix/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/manage.vue"
      ),
  },
  // min buy get free disc price
  {
    name: "promorminbgmixf_index",
    path: "/promotions/min-buy-get-free-disc-price",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/index.vue"
      ),
  },
  {
    name: "promorminbgmixf_create",
    path: "/promotions/min-buy-get-free-disc-price/create",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/manage.vue"
      ),
  },
  {
    name: "promorminbgmixf_update",
    path: "/promotions/min-buy-get-free-disc-price/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/manage.vue"
      ),
  },
];
