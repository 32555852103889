import axios from "axios";

const $axios = axios.create({
  baseURL: process.env.MIX_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  validateStatus: function validateStatus(status) {
    let default_ = status >= 200 && status < 300;
    let extra = status == 404;
    return default_ || extra;
  },
});

$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status == 403) {
    //     alert(error.response.status);
    // }
    // if (error.response.status == 500) {
    //     alert(error.response.status);
    // }
    return Promise.reject(error);
  }
);

export default $axios;
